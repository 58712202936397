.banner {
  background-image: url("../images/bannerblank.png");
  background-repeat: no-repeat;
  // height: 82vh;
  background-size: cover;
  .compimg {
    height: 678px;
    padding: 20px 0px 0px 0px;
  }
  .bannerhead {
    color: #f07f3d;
    font-size: 36px;
    font-weight: 700;
    font-family: "Inter";
    font-style: normal;
    padding: 14px 0px;
  }
  .bannerbtn {
    align-items: center;
    background-color: #f07f3d !important;
    border: none !important;
    border-radius: 8px;
    flex-direction: row;
    font-size: 20px;
    font-weight: 700;
    justify-content: center;
    line-height: 24px;
    padding: 14px 40px;
    margin-bottom: 15px;
    // position: absolute;
  }
  .social {
    font-size: 20px;
    font-weight: 700;
    font-family: "Inter";
    font-style: normal;
  }
  .icostyle {
    height: 23px;
  }
  .logostyle {
    height: 60px;
  }
  .navstyle {
    border-bottom: 1px solid #fff;
    .nav-link {
      font-family: "Inter";
      font-style: normal;
      color: #fff;
      font-weight: 700;

    }
  }
}
.services {
  background-color: #022446;
  font-family: "Inter";
  font-style: normal;
  .head {
    color: #99efff;
    font-size: 24px;
    font-weight: 700;
    padding-top: 25px;
  }
  .text-style {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
  }
  .cardstyle {
    background-color: #cecece;
    height: 245px;
    .titlestyle {
      font-size: 16px;
      font-weight: 700;
    }
    .cardtext {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .hover-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #909af4; /* Blue with opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .card:hover .hover-content {
    opacity: 1;
  }
  .know-more {
    color: #000;
    font-size: 18px;
    text-decoration: none;
    font-weight: 700;
  }
  .stackcard {
    height: 82%;
    background-color: #d9d9d9;
    &:hover {
      background: #fff;
      height: 82%;
      text-align: center;
    }
    .contentstyle:hover {
      -webkit-transform: translateY(-18px);
      transform: translateY(-18px);
      transition: -webkit-transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    }
    .languageico {
      height: 80px;
      transition: -webkit-transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    }
    .cardtext {
      font-size: 16px;
      font-weight: 400;
      // margin-bottom: 5px;
      transform: translateY(10px);
    }
  }
  .txtstyle {
    color: #fff !important;
    font-size: 20px;
    font-weight: 400;
  }
  .vmcardimg {
    background-color: #f07f3d;
    padding: 60px 30px;
    height: 270px;
  }
  .vmcard {
    background-color: #0a4067;
    padding: 20px;
    color: #fff;
    height: 270px;
  }
}
.products {
  background-image: url("../images/rectangle.png");
  background-repeat: no-repeat;
  background-size: cover;
  // height: 90vh;
  .head {
    color: #99efff;
    font-size: 24px;
    font-weight: 700;
    padding-top: 25px;
  }
  .image-container {
    overflow: hidden; /* Hide overflow to contain the zoom effect */
    margin: 0 auto; /* Center the image container */
    border-radius: 6px 6px 0px 0px;
  }

  .proimg {
    border-radius: 6px 6px 0px 0px;
    width: 100%; /* Make the image take the full width of the container */
    transition: transform 0.5s ease; /* Smooth transition for the zoom effect */
  }

  .proimg:hover {
    transform: scale(1.2); /* Scale the image to 120% on hover */
  }
  .cardfooter {
    background: #cecece;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    z-index: 9;
  }
}
.team {
  background-color: #0c2644;
  .head {
    color: #99efff;
    font-size: 24px;
    font-weight: 700;
    padding-top: 25px;
  }
  .bannerbtn {
    align-items: center;
    background-color: #f07f3d !important;
    border: none !important;
    border-radius: 8px;
    flex-direction: row;
    font-size: 20px;
    font-weight: 700;
    justify-content: center;
    line-height: 24px;
    padding: 14px 40px;
    margin-bottom: 15px;
    width: 100%;
    // position: absolute;
  }
  .icostyle {
    height: 23px;
  }
  .txtstyle {
    color: #fff !important;
    font-size: 20px;
    font-weight: 100;
  }
  .proimg {
    padding: 50px;
    border-radius: 50%;
    width: 100%; /* Make the image take the full width of the container */
  }
  .cardstyle {
    background: transparent;
    border: none;
    &:hover {
      background-color: #213853;
    }
  }
  .cardfooter {
    color: #fff !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    z-index: 9;
    border-top: 0px solid transparent;
  }
}
  /* Add this to your CSS file */
  .spinner-border.custom-spinner {
    color: white;
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
  }
  
  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: orange;
    // border-radius: 50%;
    // padding: 0.5rem;
  
  }
  .Toastify__toast-container{
    top:auto;
    left:50%;
    transform: translateX(-50%);
  }
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
  background-color: #f0f8ff7a;
}
@media (max-width:389px){
  .banner {
    background-image: url("../images/bannerblank.png");
    background-repeat: no-repeat;
    height: 100vh;
    background-size: cover;
    .compimg {
      height: 300px;
      padding: 20px 0px 0px 0px;
    }
    .bannerhead {
      color: #f07f3d;
      font-size: 24px;
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
      padding: 14px 0px;
    }
    .bannerbtn {
      align-items: center;
      background-color: #f07f3d !important;
      border: none !important;
      border-radius: 8px;
      flex-direction: row;
      font-size: 14px;
      font-weight: 700;
      justify-content: center;
      line-height: 24px;
      padding: 8px 24px;
      margin-bottom: 15px;
      // position: absolute;
    }
    .social {
      font-size: 14px;
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
    }
    .icostyle {
      height: 23px;
    }
    .logostyle {
      height: 45px;
    }
    .navstyle {
      border-bottom: 1px solid #fff;
      .nav-link {
        font-family: "Inter";
        font-style: normal;
        color: #fff;
        font-weight: 700;
  
      }
    }
  }
  .services {
    background-color: #022446;
    font-family: "Inter";
    font-style: normal;
    .serviceimg{
      height: 35px;
    }
    .head {
      color: #99efff;
      font-size: 20px;
      font-weight: 700;
      padding-top: 25px;
    }
    .text-style {
      font-size: 14px;
      font-weight: 700;
      color: #fff;
    }
  
    .cardstyle {
      background-color: #cecece;
      height: 285px;
      .titlestyle {
        font-size: 14px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 14px;
        font-weight: 400;
      }
    }
    .hover-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #909af4; /* Blue with opacity */
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  
    .card:hover .hover-content {
      opacity: 1;
    }
    .know-more {
      color: #000;
      font-size: 18px;
      text-decoration: none;
      font-weight: 700;
    }
    .stackcard {
      height: 80%;
      background-color: #d9d9d9;
      &:hover {
        background: #fff;
        height: 80%;
        text-align: center;
      }
      .contentstyle:hover {
        -webkit-transform: translateY(-14px);
        transform: translateY(-14px);
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      }
      .languageico {
        height: 80px;
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      }
      .cardtext {
        font-size: 16px;
        font-weight: 400;
        // margin-bottom: 5px;
        transform: translateY(7px);
      }
    }
    .txtstyle {
      color: #fff !important;
      font-size: 14px;
      font-weight: 400;
    }
    .vmcardimg {
      background-color: #f07f3d;
      padding: 100px 30px;
      height: 265px;
      margin-bottom: 10px;
    }
    .vmcard {
      background-color: #0a4067;
      padding: 20px;
      color: #fff;
      height: 265px;
      margin-bottom: 10px;
    }
  }
  .products {
    background-image: url("../images/rectangle.png");
    background-repeat: no-repeat;
    background-size: cover;
    // height: 90vh;
    .head {
      color: #99efff;
      font-size: 20px;
      font-weight: 700;
      padding-top: 25px;
    }
    .image-container {
      overflow: hidden; /* Hide overflow to contain the zoom effect */
      margin: 0 auto; /* Center the image container */
      border-radius: 6px 6px 0px 0px;
    }
  
    .proimg {
      border-radius: 6px 6px 0px 0px;
      width: 100%; /* Make the image take the full width of the container */
      transition: transform 0.5s ease; /* Smooth transition for the zoom effect */
    }
  
    .proimg:hover {
      transform: scale(1.2); /* Scale the image to 120% on hover */
    }
    .cardfooter {
      background: #cecece;
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      z-index: 9;
    }
  }
  .team {
    background-color: #0c2644;
    .head {
      color: #99efff;
      font-size: 20px;
      font-weight: 700;
      padding-top: 25px;
    }
    .bannerbtn {
      align-items: center;
      background-color: #f07f3d !important;
      border: none !important;
      border-radius: 8px;
      flex-direction: row;
      font-size: 14px;
      font-weight: 700;
      justify-content: center;
      line-height: 24px;
      padding: 8px 24px;
      margin-bottom: 15px;
      width: 100%;
      // position: absolute;
    }
    .icostyle {
      height: 23px;
    }
    .txtstyle {
      color: #fff !important;
      font-size: 14px;
      font-weight: 100;
    }
    .proimg {
      padding: 10px;
      border-radius: 50%;
      width: 100%; /* Make the image take the full width of the container */
    }
    .cardstyle {
      background: transparent;
      border: none;
      &:hover {
        background-color: #213853;
      }
    }
    .cardfooter {
      color: #fff !important;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      z-index: 9;
      border-top: 0px solid transparent;
    }
  }
}
@media (min-width:390px) and (max-width:416px){
  .banner {
    background-image: url("../images/bannerblank.png");
    background-repeat: no-repeat;
    height: 80vh;
    background-size: cover;
    .compimg {
      height: 300px;
      padding: 20px 0px 0px 0px;
    }
    .bannerhead {
      color: #f07f3d;
      font-size: 24px;
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
      padding: 14px 0px;
    }
    .bannerbtn {
      align-items: center;
      background-color: #f07f3d !important;
      border: none !important;
      border-radius: 8px;
      flex-direction: row;
      font-size: 14px;
      font-weight: 700;
      justify-content: center;
      line-height: 24px;
      padding: 8px 24px;
      margin-bottom: 15px;
      // position: absolute;
    }
    .social {
      font-size: 14px;
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
    }
    .icostyle {
      height: 23px;
    }
    .logostyle {
      height: 45px;
    }
    .navstyle {
      border-bottom: 1px solid #fff;
      .nav-link {
        font-family: "Inter";
        font-style: normal;
        color: #fff;
        font-weight: 700;
  
      }
    }
  }
  .services {
    background-color: #022446;
    font-family: "Inter";
    font-style: normal;
    .serviceimg{
      height: 35px;
    }
    .head {
      color: #99efff;
      font-size: 20px;
      font-weight: 700;
      padding-top: 25px;
    }
    .text-style {
      font-size: 14px;
      font-weight: 700;
      color: #fff;
    }
  
    .cardstyle {
      background-color: #cecece;
      height: 285px;
      .titlestyle {
        font-size: 14px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 14px;
        font-weight: 400;
      }
    }
    .hover-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #909af4; /* Blue with opacity */
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  
    .card:hover .hover-content {
      opacity: 1;
    }
    .know-more {
      color: #000;
      font-size: 18px;
      text-decoration: none;
      font-weight: 700;
    }
    .stackcard {
      height: 80%;
      background-color: #d9d9d9;
      &:hover {
        background: #fff;
        height: 80%;
        text-align: center;
      }
      .contentstyle:hover {
        -webkit-transform: translateY(-14px);
        transform: translateY(-14px);
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      }
      .languageico {
        height: 80px;
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      }
      .cardtext {
        font-size: 16px;
        font-weight: 400;
        // margin-bottom: 5px;
        transform: translateY(7px);
      }
    }
    .txtstyle {
      color: #fff !important;
      font-size: 14px;
      font-weight: 400;
    }
    .vmcardimg {
      background-color: #f07f3d;
      padding: 100px 30px;
      height: 265px;
      margin-bottom: 10px;
    }
    .vmcard {
      background-color: #0a4067;
      padding: 20px;
      color: #fff;
      height: 265px;
      margin-bottom: 10px;
    }
  }
  .products {
    background-image: url("../images/rectangle.png");
    background-repeat: no-repeat;
    background-size: cover;
    // height: 90vh;
    .head {
      color: #99efff;
      font-size: 20px;
      font-weight: 700;
      padding-top: 25px;
    }
    .image-container {
      overflow: hidden; /* Hide overflow to contain the zoom effect */
      margin: 0 auto; /* Center the image container */
      border-radius: 6px 6px 0px 0px;
    }
  
    .proimg {
      border-radius: 6px 6px 0px 0px;
      width: 100%; /* Make the image take the full width of the container */
      transition: transform 0.5s ease; /* Smooth transition for the zoom effect */
    }
  
    .proimg:hover {
      transform: scale(1.2); /* Scale the image to 120% on hover */
    }
    .cardfooter {
      background: #cecece;
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      z-index: 9;
    }
  }
  .team {
    background-color: #0c2644;
    .head {
      color: #99efff;
      font-size: 20px;
      font-weight: 700;
      padding-top: 25px;
    }
    .bannerbtn {
      align-items: center;
      background-color: #f07f3d !important;
      border: none !important;
      border-radius: 8px;
      flex-direction: row;
      font-size: 14px;
      font-weight: 700;
      justify-content: center;
      line-height: 24px;
      padding: 8px 24px;
      margin-bottom: 15px;
      width: 100%;
      // position: absolute;
    }
    .icostyle {
      height: 23px;
    }
    .txtstyle {
      color: #fff !important;
      font-size: 14px;
      font-weight: 100;
    }
    .proimg {
      padding: 10px;
      border-radius: 50%;
      width: 100%; /* Make the image take the full width of the container */
    }
    .cardstyle {
      background: transparent;
      border: none;
      &:hover {
        background-color: #213853;
      }
    }
    .cardfooter {
      color: #fff !important;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      z-index: 9;
      border-top: 0px solid transparent;
    }
  }
}
@media (min-width:417px) and (max-width:430px){
  .banner {
    background-image: url("../images/bannerblank.png");
    background-repeat: no-repeat;
    height: 70vh;
    background-size: cover;
    .compimg {
      height: 300px;
      padding: 20px 0px 0px 0px;
    }
    .bannerhead {
      color: #f07f3d;
      font-size: 24px;
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
      padding: 14px 0px;
    }
    .bannerbtn {
      align-items: center;
      background-color: #f07f3d !important;
      border: none !important;
      border-radius: 8px;
      flex-direction: row;
      font-size: 14px;
      font-weight: 700;
      justify-content: center;
      line-height: 24px;
      padding: 8px 24px;
      margin-bottom: 15px;
      // position: absolute;
    }
    .social {
      font-size: 14px;
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
    }
    .icostyle {
      height: 23px;
    }
    .logostyle {
      height: 45px;
    }
    .navstyle {
      border-bottom: 1px solid #fff;
      .nav-link {
        font-family: "Inter";
        font-style: normal;
        color: #fff;
        font-weight: 700;
  
      }
    }
  }
  .services {
    background-color: #022446;
    font-family: "Inter";
    font-style: normal;
    .serviceimg{
      height: 35px;
    }
    .head {
      color: #99efff;
      font-size: 20px;
      font-weight: 700;
      padding-top: 25px;
    }
    .text-style {
      font-size: 14px;
      font-weight: 700;
      color: #fff;
    }
  
    .cardstyle {
      background-color: #cecece;
      height: 285px;
      .titlestyle {
        font-size: 14px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 14px;
        font-weight: 400;
      }
    }
    .hover-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #909af4; /* Blue with opacity */
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  
    .card:hover .hover-content {
      opacity: 1;
    }
    .know-more {
      color: #000;
      font-size: 18px;
      text-decoration: none;
      font-weight: 700;
    }
    .stackcard {
      height: 80%;
      background-color: #d9d9d9;
      &:hover {
        background: #fff;
        height: 80%;
        text-align: center;
      }
      .contentstyle:hover {
        -webkit-transform: translateY(-14px);
        transform: translateY(-14px);
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      }
      .languageico {
        height: 80px;
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      }
      .cardtext {
        font-size: 16px;
        font-weight: 400;
        // margin-bottom: 5px;
        transform: translateY(7px);
      }
    }
    .txtstyle {
      color: #fff !important;
      font-size: 14px;
      font-weight: 400;
    }
    .vmcardimg {
      background-color: #f07f3d;
      padding: 100px 30px;
      height: 265px;
      margin-bottom: 10px;
    }
    .vmcard {
      background-color: #0a4067;
      padding: 20px;
      color: #fff;
      height: 265px;
      margin-bottom: 10px;
    }
  }
  .products {
    background-image: url("../images/rectangle.png");
    background-repeat: no-repeat;
    background-size: cover;
    // height: 90vh;
    .head {
      color: #99efff;
      font-size: 20px;
      font-weight: 700;
      padding-top: 25px;
    }
    .image-container {
      overflow: hidden; /* Hide overflow to contain the zoom effect */
      margin: 0 auto; /* Center the image container */
      border-radius: 6px 6px 0px 0px;
    }
  
    .proimg {
      border-radius: 6px 6px 0px 0px;
      width: 100%; /* Make the image take the full width of the container */
      transition: transform 0.5s ease; /* Smooth transition for the zoom effect */
    }
  
    .proimg:hover {
      transform: scale(1.2); /* Scale the image to 120% on hover */
    }
    .cardfooter {
      background: #cecece;
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      z-index: 9;
    }
  }
  .team {
    background-color: #0c2644;
    .head {
      color: #99efff;
      font-size: 20px;
      font-weight: 700;
      padding-top: 25px;
    }
    .bannerbtn {
      align-items: center;
      background-color: #f07f3d !important;
      border: none !important;
      border-radius: 8px;
      flex-direction: row;
      font-size: 14px;
      font-weight: 700;
      justify-content: center;
      line-height: 24px;
      padding: 8px 24px;
      margin-bottom: 15px;
      width: 100%;
      // position: absolute;
    }
    .icostyle {
      height: 23px;
    }
    .txtstyle {
      color: #fff !important;
      font-size: 14px;
      font-weight: 100;
    }
    .proimg {
      padding: 10px;
      border-radius: 50%;
      width: 100%; /* Make the image take the full width of the container */
    }
    .cardstyle {
      background: transparent;
      border: none;
      &:hover {
        background-color: #213853;
      }
    }
    .cardfooter {
      color: #fff !important;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      z-index: 9;
      border-top: 0px solid transparent;
    }
  }
}
@media (min-width:431px) and (max-width:767px){
  .banner {
    background-image: url("../images/bannerblank.png");
    background-repeat: no-repeat;
    height: 90vh;
    background-size: cover;
    .compimg {
      height: 300px;
      padding: 20px 0px 0px 0px;
    }
    .bannerhead {
      color: #f07f3d;
      font-size: 24px;
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
      padding: 14px 0px;
    }
    .bannerbtn {
      align-items: center;
      background-color: #f07f3d !important;
      border: none !important;
      border-radius: 8px;
      flex-direction: row;
      font-size: 14px;
      font-weight: 700;
      justify-content: center;
      line-height: 24px;
      padding: 8px 24px;
      margin-bottom: 15px;
      // position: absolute;
    }
    .social {
      font-size: 14px;
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
    }
    .icostyle {
      height: 23px;
    }
    .logostyle {
      height: 45px;
    }
    .navstyle {
      border-bottom: 1px solid #fff;
      .nav-link {
        font-family: "Inter";
        font-style: normal;
        color: #fff;
        font-weight: 700;
  
      }
    }
  }
  .services {
    background-color: #022446;
    font-family: "Inter";
    font-style: normal;
    .serviceimg{
      height: 35px;
    }
    .head {
      color: #99efff;
      font-size: 20px;
      font-weight: 700;
      padding-top: 25px;
    }
    .text-style {
      font-size: 14px;
      font-weight: 700;
      color: #fff;
    }
  
    .cardstyle {
      background-color: #cecece;
      height: 220px;
      .titlestyle {
        font-size: 14px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 14px;
        font-weight: 400;
      }
    }
    .hover-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #909af4; /* Blue with opacity */
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  
    .card:hover .hover-content {
      opacity: 1;
    }
    .know-more {
      color: #000;
      font-size: 18px;
      text-decoration: none;
      font-weight: 700;
    }
    .stackcard {
      height: 80%;
      background-color: #d9d9d9;
      &:hover {
        background: #fff;
        height: 80%;
        text-align: center;
      }
      .contentstyle:hover {
        -webkit-transform: translateY(-14px);
        transform: translateY(-14px);
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      }
      .languageico {
        height: 80px;
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      }
      .cardtext {
        font-size: 16px;
        font-weight: 400;
        // margin-bottom: 5px;
        transform: translateY(7px);
      }
    }
    .txtstyle {
      color: #fff !important;
      font-size: 14px;
      font-weight: 400;
    }
    .vmcardimg {
      background-color: #f07f3d;
      padding: 100px 30px;
      height: 265px;
      margin-bottom: 10px;
    }
    .vmcard {
      background-color: #0a4067;
      padding: 20px;
      color: #fff;
      height: 265px;
      margin-bottom: 10px;
    }
  }
  .products {
    background-image: url("../images/rectangle.png");
    background-repeat: no-repeat;
    background-size: cover;
    // height: 90vh;
    .head {
      color: #99efff;
      font-size: 20px;
      font-weight: 700;
      padding-top: 25px;
    }
    .image-container {
      overflow: hidden; /* Hide overflow to contain the zoom effect */
      margin: 0 auto; /* Center the image container */
      border-radius: 6px 6px 0px 0px;
    }
  
    .proimg {
      border-radius: 6px 6px 0px 0px;
      width: 100%; /* Make the image take the full width of the container */
      transition: transform 0.5s ease; /* Smooth transition for the zoom effect */
    }
  
    .proimg:hover {
      transform: scale(1.2); /* Scale the image to 120% on hover */
    }
    .cardfooter {
      background: #cecece;
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      z-index: 9;
    }
  }
  .team {
    background-color: #0c2644;
    .head {
      color: #99efff;
      font-size: 20px;
      font-weight: 700;
      padding-top: 25px;
    }
    .bannerbtn {
      align-items: center;
      background-color: #f07f3d !important;
      border: none !important;
      border-radius: 8px;
      flex-direction: row;
      font-size: 14px;
      font-weight: 700;
      justify-content: center;
      line-height: 24px;
      padding: 8px 24px;
      margin-bottom: 15px;
      // position: absolute;
      width: 100%;
    }
    .icostyle {
      height: 23px;
    }
    .txtstyle {
      color: #fff !important;
      font-size: 14px;
      font-weight: 100;
    }
    .proimg {
      padding: 10px;
      border-radius: 50%;
      width: 100%; /* Make the image take the full width of the container */
    }
    .cardstyle {
      background: transparent;
      border: none;
      &:hover {
        background-color: #213853;
      }
    }
    .cardfooter {
      color: #fff !important;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      z-index: 9;
      border-top: 0px solid transparent;
    }
  }
}
@media(min-width:768px) and (max-width:991px){
  .banner {
    background-image: url("../images/bannerblank.png");
    background-repeat: no-repeat;
    //  height: 82vh;
    background-size: 100% 100%;
    .compimg {
      height: 380px;
      padding: 20px 0px 0px 0px;
    }
    .bannerhead {
      color: #f07f3d;
      font-size: 30px;
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
      padding: 14px 0px;
    }
    .bannerbtn {
      align-items: center;
      background-color: #f07f3d !important;
      border: none !important;
      border-radius: 8px;
      flex-direction: row;
      font-size: 16px;
      font-weight: 700;
      justify-content: center;
      line-height: 24px;
      padding: 8px 25px;
      margin-bottom: 15px;
      // position: absolute;
    }
    .social {
      font-size: 16px;
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
    }
    .icostyle {
      height: 23px;
    }
    .logostyle {
      height: 60px;
    }
    .navstyle {
      border-bottom: 1px solid #fff;
      .nav-link {
        font-family: "Inter";
        font-style: normal;
        color: #fff;
        font-weight: 700;
  
      }
    }
  }
  .services {
    background-color: #022446;
    font-family: "Inter";
    font-style: normal;
    .serviceimg{
      height: 35px;
    }
    .head {
      color: #99efff;
      font-size: 20px;
      font-weight: 700;
      padding-top: 25px;
    }
    .text-style {
      font-size: 14px;
      font-weight: 700;
      color: #fff;
    }
    .cardstyle {
      background-color: #cecece;
      height: 275px;
      .titlestyle {
        font-size: 16px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 14px;
        font-weight: 400;
      }
    }
    .hover-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #909af4; /* Blue with opacity */
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  
    .card:hover .hover-content {
      opacity: 1;
    }
    .know-more {
      color: #000;
      font-size: 18px;
      text-decoration: none;
      font-weight: 700;
    }
    .stackcard {
      height: 80% !important;
      background-color: #d9d9d9;
      &:hover {
        background: #fff;
        height: 80% !important;
        text-align: center;
      }
      .contentstyle:hover {
        -webkit-transform: translateY(-18px);
        transform: translateY(-18px);
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      }
      .languageico {
        height: 65px;
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      }
      .cardtext {
        font-size: 11px;
        font-weight: 400;
        // margin-bottom: 5px;
        transform: translateY(10px);
      }
    }
    
    .txtstyle {
      color: #fff !important;
      font-size: 14px;
      font-weight: 400;
    }
    .vmcardimg {
      background-color: #f07f3d;
      padding: 100px 25px;
      height: 270px;
    }
    .vmcard {
      background-color: #0a4067;
      padding: 20px;
      color: #fff;
      height: 270px;
    }
  }
  .products {
    background-image: url("../images/rectangle.png");
    background-repeat: no-repeat;
    background-size: cover;
    // height: 90vh;
    .head {
      color: #99efff;
      font-size: 20px;
      font-weight: 700;
      padding-top: 25px;
    }
    .image-container {
      overflow: hidden; /* Hide overflow to contain the zoom effect */
      margin: 0 auto; /* Center the image container */
      border-radius: 6px 6px 0px 0px;
    }
  
    .proimg {
      border-radius: 6px 6px 0px 0px;
      width: 100%; /* Make the image take the full width of the container */
      transition: transform 0.5s ease; /* Smooth transition for the zoom effect */
    }
  
    .proimg:hover {
      transform: scale(1.2); /* Scale the image to 120% on hover */
    }
    .cardfooter {
      background: #cecece;
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      z-index: 9;
    }
  }
  .team {
    background-color: #0c2644;
    .head {
      color: #99efff;
      font-size: 20px;
      font-weight: 700;
      padding-top: 25px;
    }
    .bannerbtn {
      align-items: center;
      background-color: #f07f3d !important;
      border: none !important;
      border-radius: 8px;
      flex-direction: row;
      font-size: 16px;
      font-weight: 700;
      justify-content: center;
      line-height: 24px;
      padding: 8px 25px;
      margin-bottom: 15px;
      width: 100%;
      // position: absolute;
    }
    .icostyle {
      height: 23px;
    }
    .txtstyle {
      color: #fff !important;
      font-size: 16px;
      font-weight: 100;
    }
    .proimg {
      padding: 10px;
      border-radius: 50%;
      width: 100%; /* Make the image take the full width of the container */
    }
    .cardstyle {
      background: transparent;
      border: none;
      &:hover {
        background-color: #213853;
      }
    }
    .cardfooter {
      color: #fff !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      z-index: 9;
      border-top: 0px solid transparent;
    }
  }
}
@media(min-width:992px) and (max-width:1024px){
  .banner {
    background-image: url("../images/bannerblank.png");
    background-repeat: no-repeat;
    //  height: 82vh;
    background-size: 100% 100%;
    .compimg {
      height: 380px;
      padding: 20px 0px 0px 0px;
    }
    .bannerhead {
      color: #f07f3d;
      font-size: 28px;
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
      padding: 14px 0px;
    }
    .bannerbtn {
      align-items: center;
      background-color: #f07f3d !important;
      border: none !important;
      border-radius: 8px;
      flex-direction: row;
      font-size: 14px;
      font-weight: 700;
      justify-content: center;
      line-height: 24px;
      padding: 4px 20px;
      margin-bottom: 15px;
      // position: absolute;
    }
    .social {
      font-size: 14px;
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
    }
    .icostyle {
      height: 15px;
    }
    .logostyle {
      height: 60px;
    }
    .navstyle {
      border-bottom: 1px solid #fff;
      .nav-link {
        font-family: "Inter";
        font-style: normal;
        color: #fff;
        font-weight: 700;
        font-size: 12px;
      }
    }
  }
  .services {
    background-color: #022446;
    font-family: "Inter";
    font-style: normal;
    .serviceimg{
      height: 35px;
    }
    .head {
      color: #99efff;
      font-size: 18px;
      font-weight: 700;
      padding-top: 25px;
    }
    .text-style {
      font-size: 12px;
      font-weight: 700;
      color: #fff;
    }
    .cardstyle {
      background-color: #cecece;
      height: 275px;
      .titlestyle {
        font-size: 14px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 12px;
        font-weight: 400;
      }
    }
    .hover-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #909af4; /* Blue with opacity */
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  
    .card:hover .hover-content {
      opacity: 1;
    }
    .know-more {
      color: #000;
      font-size: 12px;
      text-decoration: none;
      font-weight: 700;
    }
    .stackcard {
      height: 68% !important;
      background-color: #d9d9d9;
      &:hover {
        background: #fff;
        height: 68% !important;
        text-align: center;
      }
      .contentstyle:hover {
        -webkit-transform: translateY(-18px);
        transform: translateY(-18px);
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      }
      .languageico {
        height: 40px;
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      }
      .cardtext {
        font-size: 12px;
        font-weight: 400;
        // margin-bottom: 5px;
        transform: translateY(10px);
      }
    }
    .txtstyle {
      color: #fff !important;
      font-size: 12px;
      font-weight: 400;
    }
    .vmcardimg {
      background-color: #f07f3d;
      padding: 103px 20px;
      height: 250px;
    }
    .vmcard {
      background-color: #0a4067;
      padding: 20px;
      color: #fff;
      height: 250px;
    }
  }
  .products {
    background-image: url("../images/rectangle.png");
    background-repeat: no-repeat;
    background-size: cover;
    // height: 90vh;
    .head {
      color: #99efff;
      font-size: 18px;
      font-weight: 700;
      padding-top: 25px;
    }
    .image-container {
      overflow: hidden; /* Hide overflow to contain the zoom effect */
      margin: 0 auto; /* Center the image container */
      border-radius: 6px 6px 0px 0px;
    }
  
    .proimg {
      border-radius: 6px 6px 0px 0px;
      width: 100%; /* Make the image take the full width of the container */
      transition: transform 0.5s ease; /* Smooth transition for the zoom effect */
    }
  
    .proimg:hover {
      transform: scale(1.2); /* Scale the image to 120% on hover */
    }
    .cardfooter {
      background: #cecece;
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      z-index: 9;
      padding: 5px;
    }
  }
  .team {
    background-color: #0c2644;
    .fieldstyle{
      font-size: 12px;
      height: 26px;
    }
    .head {
      color: #99efff;
      font-size: 18px;
      font-weight: 700;
      padding-top: 0px;
    }

    .bannerbtn {
      align-items: center;
      background-color: #f07f3d !important;
      border: none !important;
      border-radius: 8px;
      flex-direction: row;
      font-size: 14px;
      font-weight: 700;
      justify-content: center;
      line-height: 24px;
      padding: 4px 20px;
      margin-bottom: 15px;
      width: 100%;
      // position: absolute;
    }
    .icostyle {
      height: 15px;
    }
    .txtstyle {
      color: #fff !important;
      font-size: 12px;
      font-weight: 100;
    }
    .proimg {
      padding: 0px;
      border-radius: 50%;
      width: 100%; /* Make the image take the full width of the container */
    }
    .cardstyle {
      background: transparent;
      border: none;
      &:hover {
        background-color: #213853;
      }
    }
    .cardfooter {
      color: #fff !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      z-index: 9;
      border-top: 0px solid transparent;
    }
    .card-text{
      font-size: 12px;
    }
  }
}
@media(min-width:1025px) and (max-width:1280px){
  .banner {
    background-image: url("../images/bannerblank.png");
    background-repeat: no-repeat;
    //  height: 82vh;
    background-size: 100% 100%;
    .compimg {
      height: 380px;
      padding: 20px 0px 0px 0px;
    }
    .bannerhead {
      color: #f07f3d;
      font-size: 28px;
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
      padding: 14px 0px;
    }
    .bannerbtn {
      align-items: center;
      background-color: #f07f3d !important;
      border: none !important;
      border-radius: 8px;
      flex-direction: row;
      font-size: 14px;
      font-weight: 700;
      justify-content: center;
      line-height: 24px;
      padding: 4px 20px;
      margin-bottom: 15px;
      // position: absolute;
    }
    .social {
      font-size: 14px;
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
    }
    .icostyle {
      height: 15px;
    }
    .logostyle {
      height: 60px;
    }
    .navstyle {
      border-bottom: 1px solid #fff;
      .nav-link {
        font-family: "Inter";
        font-style: normal;
        color: #fff;
        font-weight: 700;
        font-size: 12px;
      }
    }
  }
  .services {
    background-color: #022446;
    font-family: "Inter";
    font-style: normal;
    .serviceimg{
      height: 35px;
    }
    .head {
      color: #99efff;
      font-size: 18px;
      font-weight: 700;
      padding-top: 25px;
    }
    .text-style {
      font-size: 12px;
      font-weight: 700;
      color: #fff;
    }
    .cardstyle {
      background-color: #cecece;
      height: 225px;
      .titlestyle {
        font-size: 14px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 12px;
        font-weight: 400;
      }
    }
    .hover-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #909af4; /* Blue with opacity */
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  
    .card:hover .hover-content {
      opacity: 1;
    }
    .know-more {
      color: #000;
      font-size: 12px;
      text-decoration: none;
      font-weight: 700;
    }
    .stackcard {
      height: 75% !important;
      background-color: #d9d9d9;
      &:hover {
        background: #fff;
        height: 75% !important;
        text-align: center;
      }
      .contentstyle:hover {
        -webkit-transform: translateY(-18px);
        transform: translateY(-18px);
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      }
      .languageico {
        height: 50px;
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      }
      .cardtext {
        font-size: 12px;
        font-weight: 400;
        // margin-bottom: 5px;
        transform: translateY(10px);
      }
    }
    .txtstyle {
      color: #fff !important;
      font-size: 12px;
      font-weight: 400;
    }
    .vmcardimg {
      background-color: #f07f3d;
      padding: 65px 20px;
      height: 210px;
    }
    .vmcard {
      background-color: #0a4067;
      padding: 20px;
      color: #fff;
      height: 210px;
    }
  }
  .products {
    background-image: url("../images/rectangle.png");
    background-repeat: no-repeat;
    background-size: cover;
    // height: 90vh;
    .head {
      color: #99efff;
      font-size: 18px;
      font-weight: 700;
      padding-top: 25px;
    }
    .image-container {
      overflow: hidden; /* Hide overflow to contain the zoom effect */
      margin: 0 auto; /* Center the image container */
      border-radius: 6px 6px 0px 0px;
    }
  
    .proimg {
      border-radius: 6px 6px 0px 0px;
      width: 100%; /* Make the image take the full width of the container */
      transition: transform 0.5s ease; /* Smooth transition for the zoom effect */
    }
  
    .proimg:hover {
      transform: scale(1.2); /* Scale the image to 120% on hover */
    }
    .cardfooter {
      background: #cecece;
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      z-index: 9;
      padding: 5px;
    }
  }
  .team {
    background-color: #0c2644;
    .head {
      color: #99efff;
      font-size: 18px;
      font-weight: 700;
      padding-top: 0px;
    }
    .fieldstyle{
      font-size: 14px;
      height: 30px;
    }
    .bannerbtn {
      align-items: center;
      background-color: #f07f3d !important;
      border: none !important;
      border-radius: 8px;
      flex-direction: row;
      font-size: 14px;
      font-weight: 700;
      justify-content: center;
      line-height: 24px;
      padding: 4px 20px;
      margin-bottom: 15px;
      width: 100%;
      // position: absolute;
    }
    .icostyle {
      height: 15px;
    }
    .txtstyle {
      color: #fff !important;
      font-size: 12px;
      font-weight: 100;
    }
    .proimg {
      padding: 0px;
      border-radius: 50%;
      width: 100%; /* Make the image take the full width of the container */
    }
    .cardstyle {
      background: transparent;
      border: none;
      &:hover {
        background-color: #213853;
      }
    }
    .cardfooter {
      color: #fff !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      z-index: 9;
      border-top: 0px solid transparent;
    }
    .card-text{
      font-size: 12px;
    }
  }
}
@media(min-width:1281px) and (max-width:1440px){
  .banner {
    background-image: url("../images/bannerblank.png");
    background-repeat: no-repeat;
    //  height: 82vh;
    background-size: 100% 100%;
    .compimg {
      height: 380px;
      padding: 20px 0px 0px 0px;
    }
    .bannerhead {
      color: #f07f3d;
      font-size: 28px;
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
      padding: 14px 0px;
    }
    .bannerbtn {
      align-items: center;
      background-color: #f07f3d !important;
      border: none !important;
      border-radius: 8px;
      flex-direction: row;
      font-size: 14px;
      font-weight: 700;
      justify-content: center;
      line-height: 24px;
      padding: 4px 20px;
      margin-bottom: 15px;
      // position: absolute;
    }
    .social {
      font-size: 14px;
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
    }
    .icostyle {
      height: 18px;
    }
    .logostyle {
      height: 60px;
    }
    .navstyle {
      border-bottom: 1px solid #fff;
      .nav-link {
        font-family: "Inter";
        font-style: normal;
        color: #fff;
        font-weight: 700;
  font-size: 14px;
      }
    }
  }
  .services {
    background-color: #022446;
    font-family: "Inter";
    font-style: normal;
    .serviceimg{
      height: 35px;
    }
    .head {
      color: #99efff;
      font-size: 18px;
      font-weight: 700;
      padding-top: 25px;
    }
    .text-style {
      font-size: 14px;
      font-weight: 700;
      color: #fff;
    }
    .cardstyle {
      background-color: #cecece;
      height: 235px;
      .titlestyle {
        font-size: 14px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 14px;
        font-weight: 400;
      }
    }
    .hover-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #909af4; /* Blue with opacity */
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  
    .card:hover .hover-content {
      opacity: 1;
    }
    .know-more {
      color: #000;
      font-size: 14px;
      text-decoration: none;
      font-weight: 700;
    }
    .stackcard {
      height: 80% !important;
      background-color: #d9d9d9;
      &:hover {
        background: #fff;
        height: 80% !important;
        text-align: center;
      }
      .contentstyle:hover {
        -webkit-transform: translateY(-18px);
        transform: translateY(-18px);
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      }
      .languageico {
        height: 70px;
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      }
      .cardtext {
        font-size: 12px;
        font-weight: 400;
        // margin-bottom: 5px;
        transform: translateY(10px);
      }
    }
    .txtstyle {
      color: #fff !important;
      font-size: 14px;
      font-weight: 400;
    }
    .vmcardimg {
      background-color: #f07f3d;
      padding: 65px 20px;
      height: 210px;
    }
    .vmcard {
      background-color: #0a4067;
      padding: 13px;
      color: #fff;
      height: 210px;
    }
  }
  .products {
    background-image: url("../images/rectangle.png");
    background-repeat: no-repeat;
    background-size: cover;
    // height: 90vh;
    .head {
      color: #99efff;
      font-size: 18px;
      font-weight: 700;
      padding-top: 25px;
    }
    .image-container {
      overflow: hidden; /* Hide overflow to contain the zoom effect */
      margin: 0 auto; /* Center the image container */
      border-radius: 6px 6px 0px 0px;
    }
  
    .proimg {
      border-radius: 6px 6px 0px 0px;
      width: 100%; /* Make the image take the full width of the container */
      transition: transform 0.5s ease; /* Smooth transition for the zoom effect */
    }
  
    .proimg:hover {
      transform: scale(1.2); /* Scale the image to 120% on hover */
    }
    .cardfooter {
      background: #cecece;
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      z-index: 9;
      padding: 5px;
    }
  }
  .team {
    background-color: #0c2644;
    .fieldstyle{
      font-size: 14px;
      height: 30px;
    }
    .head {
      color: #99efff;
      font-size: 18px;
      font-weight: 700;
      padding-top: 0px;
    }
    .bannerbtn {
      align-items: center;
      background-color: #f07f3d !important;
      border: none !important;
      border-radius: 8px;
      flex-direction: row;
      font-size: 14px;
      font-weight: 700;
      justify-content: center;
      line-height: 24px;
      padding: 4px 20px;
      margin-bottom: 15px;
      width: 100%;
      // position: absolute;
    }
    .icostyle {
      height: 18px;
    }
    .txtstyle {
      color: #fff !important;
      font-size: 14px;
      font-weight: 100;
    }
    .proimg {
      padding: 0px;
      border-radius: 50%;
      width: 100%; /* Make the image take the full width of the container */
    }
    .cardstyle {
      background: transparent;
      border: none;
      &:hover {
        background-color: #213853;
      }
    }
    .cardfooter {
      color: #fff !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      z-index: 9;
      border-top: 0px solid transparent;
    }
    .card-text{
      font-size: 14px;
    }
  }
}
@media(min-width:1441px) and (max-width:1600px){
  .banner {
    background-image: url("../images/bannerblank.png");
    background-repeat: no-repeat;
     height: 80vh;
    background-size: cover;
    .compimg {
      height:500px;
      padding: 20px 0px 0px 0px;
    }
    .bannerhead {
      color: #f07f3d;
      font-size: 36px;
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
      padding: 14px 0px;
    }
    .bannerbtn {
      align-items: center;
      background-color: #f07f3d !important;
      border: none !important;
      border-radius: 8px;
      flex-direction: row;
      font-size: 16px;
      font-weight: 700;
      justify-content: center;
      line-height: 24px;
      padding: 9px 25px;
      margin-bottom: 15px;
      // position: absolute;
    }
    .social {
      font-size: 16px;
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
    }
    .icostyle {
      height: 23px;
    }
    .logostyle {
      height: 60px;
    }
    .navstyle {
      border-bottom: 1px solid #fff;
      .nav-link {
        font-family: "Inter";
        font-style: normal;
        color: #fff;
        font-weight: 700;
  
      }
    }
  }
  .services {
    background-color: #022446;
    font-family: "Inter";
    font-style: normal;
    .serviceimg{
      height: 40px;
    }
    .head {
      color: #99efff;
      font-size: 24px;
      font-weight: 700;
      padding-top: 25px;
    }
    .text-style {
      font-size: 16px;
      font-weight: 700;
      color: #fff;
    }
    .cardstyle {
      background-color: #cecece;
      height: 265px;
      .titlestyle {
        font-size: 16px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 16px;
        font-weight: 400;
      }
    }
    .hover-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #909af4; /* Blue with opacity */
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  
    .card:hover .hover-content {
      opacity: 1;
    }
    .know-more {
      color: #000;
      font-size: 16px;
      text-decoration: none;
      font-weight: 700;
    }
    .stackcard {
      height: 82%;
      background-color: #d9d9d9;
      &:hover {
        background: #fff;
        height: 82%;
        text-align: center;
      }
      .contentstyle:hover {
        -webkit-transform: translateY(-18px);
        transform: translateY(-18px);
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      }
      .languageico {
        height: 80px;
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      }
      .cardtext {
        font-size: 16px;
        font-weight: 400;
        // margin-bottom: 5px;
        transform: translateY(10px);
      }
    }
    .txtstyle {
      color: #fff !important;
      font-size: 16px;
      font-weight: 400;
    }
    .vmcardimg {
      background-color: #f07f3d;
      padding: 75px 30px;
      height: 250px;
    }
    .vmcard {
      background-color: #0a4067;
      padding: 20px;
      color: #fff;
      height: 250px;
    }
  }
  .products {
    background-image: url("../images/rectangle.png");
    background-repeat: no-repeat;
    background-size: cover;
    // height: 90vh;
    .head {
      color: #99efff;
      font-size: 24px;
      font-weight: 700;
      padding-top: 25px;
    }
    .image-container {
      overflow: hidden; /* Hide overflow to contain the zoom effect */
      margin: 0 auto; /* Center the image container */
      border-radius: 6px 6px 0px 0px;
    }
  
    .proimg {
      border-radius: 6px 6px 0px 0px;
      width: 100%; /* Make the image take the full width of the container */
      transition: transform 0.5s ease; /* Smooth transition for the zoom effect */
    }
  
    .proimg:hover {
      transform: scale(1.2); /* Scale the image to 120% on hover */
    }
    .cardfooter {
      background: #cecece;
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      z-index: 9;
    }
  }
  .team {
    background-color: #0c2644;
    .fieldstyle{
      font-size: 16px;
      height: 30px;
    }
    .head {
      color: #99efff;
      font-size: 24px;
      font-weight: 700;
      padding-top: 25px;
    }
    .bannerbtn {
      align-items: center;
      background-color: #f07f3d !important;
      border: none !important;
      border-radius: 8px;
      flex-direction: row;
      font-size: 20px;
      font-weight: 700;
      justify-content: center;
      line-height: 24px;
      padding: 14px 40px;
      margin-bottom: 15px;
      width: 100%;
      // position: absolute;
    }
    .icostyle {
      height: 23px;
    }
    .txtstyle {
      color: #fff !important;
      font-size: 16px;
      font-weight: 100;
    }
    .proimg {
      padding: 14px;
      border-radius: 50%;
      width: 100%; /* Make the image take the full width of the container */
    }
    .cardstyle {
      background: transparent;
      border: none;
      &:hover {
        background-color: #213853;
      }
    }
    .cardfooter {
      color: #fff !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      z-index: 9;
      border-top: 0px solid transparent;
    }
  }
}
@media(min-width:1601px) and (max-width:1680px){
  .banner {
    background-image: url("../images/bannerblank.png");
    background-repeat: no-repeat;
     height: 80vh;
    background-size: cover;
    .compimg {
      height: 678px;
      padding: 20px 0px 0px 0px;
    }
    .bannerhead {
      color: #f07f3d;
      font-size: 36px;
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
      padding: 14px 0px;
    }
    .bannerbtn {
      align-items: center;
      background-color: #f07f3d !important;
      border: none !important;
      border-radius: 8px;
      flex-direction: row;
      font-size: 18px;
      font-weight: 700;
      justify-content: center;
      line-height: 24px;
      padding: 14px 40px;
      margin-bottom: 15px;
      // position: absolute;
    }
    .social {
      font-size: 18px;
      font-weight: 700;
      font-family: "Inter";
      font-style: normal;
    }
    .icostyle {
      height: 23px;
    }
    .logostyle {
      height: 60px;
    }
    .navstyle {
      border-bottom: 1px solid #fff;
      .nav-link {
        font-family: "Inter";
        font-style: normal;
        color: #fff;
        font-weight: 700;
  
      }
    }
  }
  .services {
    background-color: #022446;
    font-family: "Inter";
    font-style: normal;
    .head {
      color: #99efff;
      font-size: 24px;
      font-weight: 700;
      padding-top: 25px;
    }
    .text-style {
      font-size: 16px;
      font-weight: 700;
      color: #fff;
    }
    .cardstyle {
      background-color: #cecece;
      height: 245px;
      .titlestyle {
        font-size: 16px;
        font-weight: 700;
      }
      .cardtext {
        font-size: 16px;
        font-weight: 400;
      }
    }
    .hover-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #909af4; /* Blue with opacity */
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  
    .card:hover .hover-content {
      opacity: 1;
    }
    .know-more {
      color: #000;
      font-size: 18px;
      text-decoration: none;
      font-weight: 700;
    }
    .stackcard {
      height: 82%;
      background-color: #d9d9d9;
      &:hover {
        background: #fff;
        height: 82%;
        text-align: center;
      }
      .contentstyle:hover {
        -webkit-transform: translateY(-18px);
        transform: translateY(-18px);
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      }
      .languageico {
        height: 80px;
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      }
      .cardtext {
        font-size: 16px;
        font-weight: 400;
        // margin-bottom: 5px;
        transform: translateY(10px);
      }
    }
    .txtstyle {
      color: #fff !important;
      font-size: 18px;
      font-weight: 400;
    }
    .vmcardimg {
      background-color: #f07f3d;
      padding: 60px 30px;
      height: 270px;
    }
    .vmcard {
      background-color: #0a4067;
      padding: 20px;
      color: #fff;
      height: 270px;
    }
  }
  .products {
    background-image: url("../images/rectangle.png");
    background-repeat: no-repeat;
    background-size: cover;
    // height: 90vh;
    .head {
      color: #99efff;
      font-size: 24px;
      font-weight: 700;
      padding-top: 25px;
    }
    .image-container {
      overflow: hidden; /* Hide overflow to contain the zoom effect */
      margin: 0 auto; /* Center the image container */
      border-radius: 6px 6px 0px 0px;
    }
  
    .proimg {
      border-radius: 6px 6px 0px 0px;
      width: 100%; /* Make the image take the full width of the container */
      transition: transform 0.5s ease; /* Smooth transition for the zoom effect */
    }
  
    .proimg:hover {
      transform: scale(1.2); /* Scale the image to 120% on hover */
    }
    .cardfooter {
      background: #cecece;
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      z-index: 9;
    }
  }
  .team {
    background-color: #0c2644;
    .fieldstyle{
      font-size: 18px;
      height: 30px;
    }
    .head {
      color: #99efff;
      font-size: 24px;
      font-weight: 700;
      padding-top: 25px;
    }
    .bannerbtn {
      align-items: center;
      background-color: #f07f3d !important;
      border: none !important;
      border-radius: 8px;
      flex-direction: row;
      font-size: 20px;
      font-weight: 700;
      justify-content: center;
      line-height: 24px;
      padding: 14px 40px;
      margin-bottom: 15px;
      width: 100%;
      // position: absolute;
    }
    .icostyle {
      height: 23px;
    }
    .txtstyle {
      color: #fff !important;
      font-size: 18px;
      font-weight: 100;
    }
    .proimg {
      padding: 25px;
      border-radius: 50%;
      width: 100%; /* Make the image take the full width of the container */
    }
    .cardstyle {
      background: transparent;
      border: none;
      &:hover {
        background-color: #213853;
      }
    }
    .cardfooter {
      color: #fff !important;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      z-index: 9;
      border-top: 0px solid transparent;
    }
  }
}